import React from 'react';

const Documentation = () => {
  return (
    <div className="poppins container p-4 text-gray-900 min-h-screen animate-fadeIn">
      <div className="md:ml-48">
        <h1 className="text-2xl font-bold mb-4 mt-16 underline">Documentation</h1>
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Installation</h2>
          <p className="mb-4">
            To get started with our component library, you need to install Tailwind CSS and our
            custom components. Follow the steps below:
          </p>
          <ol className="list-decimal list-inside mb-4">
            <li className="mb-2">Install Tailwind CSS via npm:</li>
            <pre
              className="bg-gray-800 text-white p-4 rounded-md overflow-auto mb-4"
              aria-label="Code snippet for Tailwind CSS installation"
            >
              <code>{`> npm install tailwindcss`}</code>
            </pre>
            <li className="mb-2">Initialize Tailwind CSS configuration:</li>
            <pre
              className="bg-gray-800 text-white p-4 rounded-md overflow-auto mb-4"
              aria-label="Code snippet for Tailwind CSS initialization"
            >
              <code>{`> npx tailwindcss init`}</code>
            </pre>
            <li className="mb-2">Add Tailwind CSS to your CSS file:</li>
            <pre
              className="bg-gray-800 text-white p-4 rounded-md overflow-auto mb-4"
              aria-label="Code snippet for adding Tailwind CSS"
            >
              <code>
                {`@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";`}
              </code>
            </pre>
          </ol>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Usage</h2>
          <p className="mb-4">You can use the components in your project as follows:</p>
          <pre
            className="bg-gray-800 text-white p-4 rounded-md overflow-auto mb-4"
            aria-label="Code snippet for component usage"
          >
            <code>
              {`import React from 'react';

const App = () => {
  return (
    <div className="p-4">
      {/* Our Component Code Here */}
    </div>
  );
};

export default App;`}
            </code>
          </pre>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Customization</h2>
          <p className="mb-4">
            Tailwind CSS provides a highly customizable framework. You can extend the default theme,
            modify existing styles, or add new utilities by configuring the{' '}
            <code>tailwind.config.js</code> file:
          </p>
          <pre
            className="bg-gray-800 text-white p-4 rounded-md overflow-auto mb-4"
            aria-label="Code snippet for customization"
          >
            <code>
              {`module.exports = {
  theme: {
    extend: {
      colors: {
        'custom-blue': '#1fb6ff',
        'custom-pink': '#ff49db',
      },
    },
  },
  variants: {},
  plugins: [],
}`}
            </code>
          </pre>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Official Documentation</h2>
          <p className="mb-4">
            For more detailed information and advanced configuration options, please visit the
            official Tailwind CSS documentation:
          </p>
          <a
            className="underline"
            href="https://tailwindcss.com/docs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tailwind CSS Documentation
          </a>
        </section>
      </div>
    </div>
  );
};

export default Documentation;
