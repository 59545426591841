import React from 'react';
import PropTypes from 'prop-types';

const NFTCardDark = ({ image, name, description }) => (
  <div className="bg-gray-800 border border-black rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 dark:bg-gray-900">
    <img src={image} alt={name} className="w-full h-48 object-cover bg-white" />
    <div className="p-4">
      <h3 className="text-lg font-bold text-white">{name}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
  </div>
);

NFTCardDark.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const NFTGalleryDark = ({ nfts }) => {
  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-8 poppins text-gray-700">Gallery</h2>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {nfts.map((nft, index) => (
          <NFTCardDark key={index} {...nft} />
        ))}
      </div>
    </div>
  );
};

NFTGalleryDark.propTypes = {
  nfts: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default NFTGalleryDark;
