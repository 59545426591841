import React from 'react';
import PropTypes from 'prop-types';

const PriceTicker = ({ name, price, change, marketCap }) => {
  const changeColor = change > 0 ? 'text-green-400' : 'text-red-400';
  return (
    <div className="bg-gray-900 shadow-md rounded-lg p-4 flex items-center justify-between">
      <div>
        <h3 className="text-lg font-bold text-gray-100">{name}</h3>
        <p className="text-sm text-gray-400">Market Cap: ${marketCap}</p>
      </div>
      <div className="text-right">
        <p className="text-xl font-semibold text-gray-100">${price}</p>
        <p className={`text-sm ${changeColor}`}>{change}%</p>
      </div>
    </div>
  );
};

PriceTicker.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  change: PropTypes.number.isRequired,
  marketCap: PropTypes.number.isRequired,
};

export default PriceTicker;
