import React from 'react';
import TokenBalance from '../../components/TokenBalanceDark';
import Code from '../../components/Reserve/Code';
import ethLogo from '../../assets/eth.png';
import bscLogo from '../../assets/bsc.png';
import maticLogo from '../../assets/matic.png';

const code = `import React from 'react';

const TokenBalance = ({ tokenName, balance, logo }) => {
  return (
    <div className="flex items-center bg-gray-900 shadow-lg p-4 rounded-lg">
      <img src={logo} alt={\`\${tokenName} logo\`} className="w-12 h-12 rounded-full mr-4" />
      <div className="flex flex-col">
        <span className="text-lg font-bold text-white">{tokenName}</span>
        <span className="text-sm text-gray-400">{balance} tokens</span>
      </div>
    </div>
  );
};

export default TokenBalance;
`;

const tokenData = [
  {
    tokenName: 'Ethereum',
    balance: 10.5,
    logo: ethLogo,
  },
  {
    tokenName: 'Binance Smart Chain',
    balance: 150.2,
    logo: bscLogo,
  },
  {
    tokenName: 'Polygon',
    balance: 2000.45,
    logo: maticLogo,
  },
];

const TokenBalanceDarkPage = () => {
  return (
    <div className="mt-16 container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-8 poppins">Component - Token Balance Display</h2>
      <div className="overflow-auto mt-4 flex justify-center py-12 component-container">
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {tokenData.map((token, index) => (
            <TokenBalance
              key={index}
              tokenName={token.tokenName}
              balance={token.balance}
              logo={token.logo}
            />
          ))}
        </div>
      </div>
      <Code code={code} />
    </div>
  );
};

export default TokenBalanceDarkPage;
