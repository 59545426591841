import React from 'react';
import PropTypes from 'prop-types';

const TransactionHistoryTableDark = ({ transactions }) => {
  return (
    <div className="poppins container max-w-4xl">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800 text-white shadow-md rounded-lg">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b border-gray-700">Date</th>
              <th className="py-3 px-4 border-b border-gray-700">Transaction ID</th>
              <th className="py-3 px-4 border-b border-gray-700">Amount</th>
              <th className="py-3 px-4 border-b border-gray-700">Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index} className="hover:bg-gray-700 transition-colors duration-200">
                <td className="py-3 px-4 border-b border-gray-700">{transaction.date}</td>
                <td className="py-3 px-4 border-b border-gray-700">{transaction.id}</td>
                <td className="py-3 px-4 border-b border-gray-700">{transaction.amount}</td>
                <td className="py-3 px-4 border-b border-gray-700">{transaction.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

TransactionHistoryTableDark.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TransactionHistoryTableDark;
