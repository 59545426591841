import React from 'react';
import UserProfile from '../../components/UserProfile';
import Code from '../../components/Reserve/Code';
import sample from '../../assets/sample.webp';
import nft1 from '../../assets/banner.png';
import nft2 from '../../assets/component.png';

const user = {
  name: 'John Doe',
  email: 'john.doe@example.com',
  avatar: sample,
  walletAddress: '0x1234567890',
  transactions: [
    { date: '2023-07-01', id: 'TX12345', amount: '0.5 ETH', status: 'Completed' },
    { date: '2023-07-02', id: 'TX12346', amount: '1.2 ETH', status: 'Pending' },
    { date: '2023-07-03', id: 'TX12347', amount: '0.3 ETH', status: 'Failed' },
  ],
  nfts: [
    {
      image: nft1,
      name: 'NFT 1',
      description: 'This is a description for NFT 1.',
    },
    {
      image: nft2,
      name: 'NFT 2',
      description: 'This is a description for NFT 2.',
    },
  ],
};

const userCode = `
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const UserProfile = ({ user }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  return (
    <div className="mt-16 container max-w-4xl">
      <div className="bg-white shadow-lg rounded-lg p-8">
        <div className="flex items-center mb-8">
          <Avatar src={user.avatar} alt={user.name} size="h-24 w-24" style="rounded-full" />
          <div className="ml-4">
            <h3 className="text-xl font-bold text-gray-900">{user.name}</h3>
            <p className="text-gray-700 text-xs overflow-auto">{user.walletAddress}</p>
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-lg font-bold text-gray-900">User Information</h3>
          {isEditing ? (
            <form onSubmit={handleFormSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="mt-1 py-2 px-4 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="mt-1 py-2 px-4 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleEditToggle}
                className="ml-4 inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Cancel
              </button>
            </form>
          ) : (
            <div>
              <p className="text-gray-700">Name: {user.name}</p>
              <p className="text-gray-700">Email: {user.email}</p>
              <button
                onClick={handleEditToggle}
                className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Edit
              </button>
            </div>
          )}
        </div>

        <div className="mb-8">
          <h3 className="text-lg font-bold text-gray-900">Transaction History</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-gray-100 text-gray-900 shadow-md rounded-lg">
              <thead>
                <tr>
                  <th className="py-3 px-4 border-b">Date</th>
                  <th className="py-3 px-4 border-b">Transaction ID</th>
                  <th className="py-3 px-4 border-b">Amount</th>
                  <th className="py-3 px-4 border-b">Status</th>
                </tr>
              </thead>
              <tbody>
                {user.transactions.map((transaction, index) => (
                  <tr key={index} className="hover:bg-gray-200 transition-colors duration-200">
                    <td className="py-3 px-4 border-b">{transaction.date}</td>
                    <td className="py-3 px-4 border-b">{transaction.id}</td>
                    <td className="py-3 px-4 border-b">{transaction.amount}</td>
                    <td className="py-3 px-4 border-b">{transaction.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-bold text-gray-900">NFT Collection</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {user.nfts.map((nft, index) => (
              <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden">
                <img src={nft.image} alt={nft.name} className="w-full h-32 object-cover" />
                <div className="p-4">
                  <h4 className="text-md font-bold text-gray-900">{nft.name}</h4>
                  <p className="text-gray-700">{nft.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
`;

const UserProfilePage = () => {
  return (
    <div className="mt-16 container px-4">
      <h2 className="text-2xl font-bold mb-8 poppins">Component - User Profile</h2>
      <div className="overflow-auto flex justify-center py-12 pt-0 component-container">
        <UserProfile user={user} />
      </div>
      <Code code={userCode} />
    </div>
  );
};

export default UserProfilePage;
