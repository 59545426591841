import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import Navbar from './components/Reserve/Navbar';
import Footer from './components/Reserve/Footer';
import {
  // Components,
  // Documentation,
  // ENSResolverPage,
  Home,
  LandingPage,
  NotFound,
  Contact,
  Templates,
  // TokenSwapPage,
  // TokenSwapPageDark,
  // ENSResolverPageLight,
  // TransactionHistory,
  // TransactionHistoryDark,
  // UserProfilePage,
  // DarkUserProfilePage,
  // NFTGalleryPage,
  // NFTGalleryDarkPage,
  // PriceTickerPage,
  // PriceTickerDarkPage,
  // TokenBalancePage,
  // TokenBalanceDarkPage,
  // TransactionFormPage,
  // DarkTransactionFormPage,
} from './pages';
import Portfolio from './samples/portfolio/Porfolio';
import Ping from './samples/ping/Ping';
import TicTacToe from './samples/tictactoe/TicTacToe';

const AppContent = () => {
  const location = useLocation();
  const [shouldHideNavbarAndFooter, setShouldHideNavbarAndFooter] = useState(false);

  useEffect(() => {
    const hideNavbarAndFooterRoutes = ['/portfolio', '/ping', '/tictactoe'];
    setShouldHideNavbarAndFooter(hideNavbarAndFooterRoutes.includes(location.pathname));
  }, [location]);

  return (
    <>
      {!shouldHideNavbarAndFooter && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/components" element={<Components />}>
          <Route path="token-swap" element={<TokenSwapPage />} />
          <Route path="token-swap-dark" element={<TokenSwapPageDark />} />
          <Route path="ens-resolver" element={<ENSResolverPageLight />} />
          <Route path="ens-resolver-dark" element={<ENSResolverPage />} />
          <Route path="transaction-history" element={<TransactionHistory />} />
          <Route path="transaction-history-dark" element={<TransactionHistoryDark />} />
          <Route path="user-profile" element={<UserProfilePage />} />
          <Route path="user-profile-dark" element={<DarkUserProfilePage />} />
          <Route path="nft-gallery" element={<NFTGalleryPage />} />
          <Route path="nft-gallery-dark" element={<NFTGalleryDarkPage />} />
          <Route path="price-ticker" element={<PriceTickerPage />} />
          <Route path="price-ticker-dark" element={<PriceTickerDarkPage />} />
          <Route path="token-balance" element={<TokenBalancePage />} />
          <Route path="token-balance-dark" element={<TokenBalanceDarkPage />} />
          <Route path="transaction-form" element={<TransactionFormPage />} />
          <Route path="transaction-form-dark" element={<DarkTransactionFormPage />} />
        </Route> */}
        <Route path="/templates" element={<Templates />} />
        {/* <Route path="/documentation" element={<Documentation />} /> */}
        <Route path="/pages" element={<LandingPage />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/ping" element={<Ping />} />
        <Route path="/tictactoe" element={<TicTacToe />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!shouldHideNavbarAndFooter && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
