import React, { useState } from 'react';

const TransactionForm = () => {
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState('');
  const [gasFees, setGasFees] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Transaction submitted:', { recipient, amount, gasFees });
  };

  return (
    <div className="poppins py-8 bg-gradient-to-r from-gray-50 to-white text-gray-900 flex items-center justify-center">
      <div className="container max-w-md p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center mb-6">Send Tokens or ETH</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="recipient" className="block text-sm font-medium text-gray-700">
              Recipient Address
            </label>
            <input
              type="text"
              id="recipient"
              value={recipient}
              onChange={(e) => setRecipient(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black sm:text-sm"
              required
            />
          </div>
          <div>
            <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
              Amount
            </label>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black sm:text-sm"
              required
            />
          </div>
          <div>
            <label htmlFor="gasFees" className="block text-sm font-medium text-gray-700">
              Gas Fees
            </label>
            <input
              type="number"
              id="gasFees"
              value={gasFees}
              onChange={(e) => setGasFees(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black sm:text-sm"
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-black text-white px-4 py-2 rounded-md transition-colors duration-300"
            >
              Send Transaction
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TransactionForm;
