import React from 'react';
import TokenSwapInterfaceDark from '../../components/TokenSwapInterfaceDark';
import Code from '../../components/Reserve/Code';

const tokenSwapCodeDark = `
import React, { useState } from 'react';

const TokenSwapInterfaceDark = () => {
  const [inputToken, setInputToken] = useState('');
  const [outputToken, setOutputToken] = useState('');
  const [amount, setAmount] = useState('');
  const [outputAmount, setOutputAmount] = useState('');

  const handleSwap = () => {
    const calculatedOutput = (amount * 0.9).toFixed(2); // Example conversion rate
    setOutputAmount(calculatedOutput);
  };

  return (
    <div className="poppins bg-gray-900 text-white shadow-lg rounded-lg p-8 w-full max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-purple-400 mb-4 text-center">
        Token Swap
      </h1>
      <div className="mb-4">
        <label htmlFor="input-token" className="block text-sm text-gray-300 mb-2">
          From
        </label>
        <input
          id="input-token"
          type="text"
          value={inputToken}
          onChange={(e) => setInputToken(e.target.value)}
          className="w-full px-4 py-2 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 bg-gray-800 text-white"
          placeholder="Input Token"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="amount" className="block text-sm text-gray-300 mb-2">
          Amount
        </label>
        <input
          id="amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full px-4 py-2 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 bg-gray-800 text-white"
          placeholder="Amount"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="output-token" className="block text-sm text-gray-300 mb-2">
          To
        </label>
        <input
          id="output-token"
          type="text"
          value={outputToken}
          onChange={(e) => setOutputToken(e.target.value)}
          className="w-full px-4 py-2 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 bg-gray-800 text-white"
          placeholder="Output Token"
        />
      </div>
      <button
        onClick={handleSwap}
        className="w-full bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition-colors duration-300"
      >
        Swap
      </button>
      {outputAmount && (
        <div className="mt-4 text-center">
          <p className="text-lg font-semibold text-gray-300">
            You will receive: {outputAmount} {outputToken}
          </p>
        </div>
      )}
    </div>
  );
};

export default TokenSwapInterfaceDark;
`;

const TokenSwapPageDark = () => {
  return (
    <div className="mt-16 container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-8 poppins">Component - Token Swap Interface</h2>
      <div className="overflow-auto mt-4 flex justify-center py-12 component-container">
        <TokenSwapInterfaceDark />
      </div>
      <Code code={tokenSwapCodeDark} />
    </div>
  );
};

export default TokenSwapPageDark;
