import React from 'react';
import PropTypes from 'prop-types';
import './code.css';

const Code = ({ code }) => {
  return (
    <div className="overflow-auto mt-4 code-container" role="region" aria-label="Code snippet">
      <pre className="whitespace-pre-wrap text-white rounded-md">
        <code>{code}</code>
      </pre>
    </div>
  );
};

Code.propTypes = {
  code: PropTypes.string.isRequired,
};

export default Code;
