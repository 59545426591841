import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from './Reserve/Avatar';

const DarkUserProfile = ({ user }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  return (
    <div className="mt-16 container max-w-4xl">
      <div className="bg-gray-800 shadow-lg rounded-lg p-8">
        <div className="flex items-center mb-8">
          <Avatar src={user.avatar} alt={user.name} size="h-24 w-24" style="rounded-full" />
          <div className="ml-4">
            <h3 className="text-xl font-bold text-white">{user.name}</h3>
            <p className="text-gray-400 text-xs overflow-auto">{user.walletAddress}</p>
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-lg font-bold text-white">User Information</h3>
          {isEditing ? (
            <form onSubmit={handleFormSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-400">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="mt-1 py-2 px-4 block w-full border border-gray-600 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm bg-gray-900 text-white"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-400">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="mt-1 py-2 px-4 block w-full border border-gray-600 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm bg-gray-900 text-white"
                />
              </div>
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleEditToggle}
                className="ml-4 inline-flex items-center px-4 py-2 border border-gray-600 text-sm font-medium rounded-md shadow-sm text-gray-400 bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Cancel
              </button>
            </form>
          ) : (
            <div>
              <p className="text-gray-400">Name: {user.name}</p>
              <p className="text-gray-400">Email: {user.email}</p>
              <button
                onClick={handleEditToggle}
                className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Edit
              </button>
            </div>
          )}
        </div>

        <div className="mb-8">
          <h3 className="text-lg font-bold text-white">Transaction History</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-gray-900 text-gray-400 shadow-md rounded-lg">
              <thead>
                <tr>
                  <th className="py-3 px-4 border-b border-gray-700">Date</th>
                  <th className="py-3 px-4 border-b border-gray-700">Transaction ID</th>
                  <th className="py-3 px-4 border-b border-gray-700">Amount</th>
                  <th className="py-3 px-4 border-b border-gray-700">Status</th>
                </tr>
              </thead>
              <tbody>
                {user.transactions.map((transaction, index) => (
                  <tr key={index} className="hover:bg-gray-800 transition-colors duration-200">
                    <td className="py-3 px-4 border-b border-gray-700">{transaction.date}</td>
                    <td className="py-3 px-4 border-b border-gray-700">{transaction.id}</td>
                    <td className="py-3 px-4 border-b border-gray-700">{transaction.amount}</td>
                    <td className="py-3 px-4 border-b border-gray-700">{transaction.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-bold text-white">NFT Collection</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {user.nfts.map((nft, index) => (
              <div key={index} className="bg-gray-900 shadow-lg rounded-lg overflow-hidden">
                <img src={nft.image} alt={nft.name} className="w-full h-32 object-cover" />
                <div className="p-4">
                  <h4 className="text-md font-bold text-white">{nft.name}</h4>
                  <p className="text-gray-400">{nft.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

DarkUserProfile.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    walletAddress: PropTypes.string.isRequired,
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ).isRequired,
    nfts: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default DarkUserProfile;
