import React, { useState, useEffect } from 'react';

import hashnode from './assets/hashnode.png';
import LinkCard from './LinkCard';
import StickyButton from '../../components/Reserve/StickyButton';

import './index.css';

const links = [
  {
    href: '/',
    imgSrc: 'https://mrmehta.in/static/media/x.e361c6bffa1c35b70d36.webp',
    imgAlt: 'X Logo',
    bgColor: '#000',
    category: 'Social',
    title: 'X',
    desc: 'Follow me on X to see my thoughts on development trends and the latest in software engineering.',
  },
  {
    href: '/',
    imgSrc: 'https://mrmehta.in/static/media/linkedin.4c11b4693dd4eebca5be.webp',
    imgAlt: 'LinkedIn Logo',
    bgColor: '#0A66C2',
    category: 'Social',
    title: 'LinkedIn',
    desc: 'Connect with me on LinkedIn to explore my professional journey and discover opportunities in software development.',
  },
  {
    href: '/',
    imgSrc: 'https://mrmehta.in/static/media/github.74d13353bde36d2dde5b52360f829273.svg',
    imgAlt: 'GitHub Logo',
    bgColor: '#6e5494',
    category: 'Code',
    title: 'GitHub',
    desc: 'Check out my GitHub to collaborate on code repositories and explore my contributions to open-source projects.',
  },
  {
    href: '/',
    imgSrc: hashnode,
    imgAlt: 'Hashnode Logo',
    bgColor: '#2962FF',
    category: 'Blog',
    title: 'Hashnode',
    desc: 'Read my technical blogs on Hashnode where I share insights and engage with the developer community.',
  },
  {
    href: '/',
    imgSrc: 'https://mrmehta.in/static/media/dev.11cf79104e92d34771b0.png',
    imgAlt: 'Dev.to Logo',
    bgColor: '#000',
    category: 'Blog',
    title: 'Dev.to',
    desc: 'Join me on Dev.to to read my articles and participate in discussions with a vibrant community of developers.',
  },
  {
    href: '/',
    imgSrc: 'https://mrmehta.in/static/media/drive.6901936410340b358d5a.png',
    imgAlt: 'Resume Logo',
    bgColor: '#808080',
    category: 'Doc',
    title: 'Resume',
    desc: 'Take an overview of my expertise in full-stack development & building scalable solutions.',
  },
];

const Portfolio = () => {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    document.documentElement.classList.add(theme);
    return () => {
      document.documentElement.classList.remove(theme);
    };
  }, [theme]);

  useEffect(() => {
    const handleDoubleClick = () => {
      setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    document.body.addEventListener('dblclick', handleDoubleClick);

    return () => {
      document.body.removeEventListener('dblclick', handleDoubleClick);
    };
  }, []);

  return (
    <div
      className="portfolio flex flex-col items-center justify-center bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 dark:from-gray-900 dark:via-gray-800 dark:to-gray-700 poppins pb-8"
      style={{ minHeight: '100vh' }}
    >
      <header className="text-2xl font-extrabold pt-8 text-gray-900 dark:text-gray-100 animate-pulse">
        Tailwine
      </header>
      <p className="text-lg flex justify-center mb-4 text-gray-600 dark:text-gray-400">
        <span className="text-center mb-4 mx-4 md:mx-0 lg:mx-0">
          Double click to change the theme!
        </span>
      </p>
      <main className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        {links.map((link, index) => (
          <LinkCard key={index} {...link} />
        ))}
      </main>
      <StickyButton />
    </div>
  );
};

export default Portfolio;
