import React from 'react';
import TransactionHistoryTableDark from '../../components/TransactionHistoryTableDark';
import Code from '../../components/Reserve/Code';

const transactions = [
  { date: '2023-07-01', id: 'TX12345', amount: '0.5 ETH', status: 'Completed' },
  { date: '2023-07-02', id: 'TX12346', amount: '1.2 ETH', status: 'Pending' },
  { date: '2023-07-03', id: 'TX12347', amount: '0.3 ETH', status: 'Failed' },
];

const TransactionHistoryDark = () => {
  const codeString = `
import React from 'react';
import PropTypes from 'prop-types';

const TransactionHistoryTableDark = ({ transactions }) => {
  return (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800 text-white shadow-md rounded-lg">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b border-gray-700">Date</th>
              <th className="py-3 px-4 border-b border-gray-700">Transaction ID</th>
              <th className="py-3 px-4 border-b border-gray-700">Amount</th>
              <th className="py-3 px-4 border-b border-gray-700">Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index} className="hover:bg-gray-700 transition-colors duration-200">
                <td className="py-3 px-4 border-b border-gray-700">{transaction.date}</td>
                <td className="py-3 px-4 border-b border-gray-700">{transaction.id}</td>
                <td className="py-3 px-4 border-b border-gray-700">{transaction.amount}</td>
                <td className="py-3 px-4 border-b border-gray-700">{transaction.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  );
};

export default TransactionHistoryTableDark;
  `;

  return (
    <div className="mt-16 container px-4">
      <h2 className="text-2xl font-bold mb-8 poppins">Component - Transaction History</h2>
      <div className="overflow-auto mt-4 flex justify-center py-12 component-container">
        <TransactionHistoryTableDark transactions={transactions} />
      </div>
      <Code code={codeString} />
    </div>
  );
};

export default TransactionHistoryDark;
