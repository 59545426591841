import React from 'react';

const LinkCard = ({ href, imgSrc, imgAlt, bgColor, category, title, desc }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" aria-label={`${title} - ${desc}`}>
    <div
      className="bg-white dark:bg-gray-800 shadow-lg  p-4 relative transition-transform transform hover:scale-105"
      style={{ maxWidth: '330px' }}
    >
      <div className="absolute inset-0 border-2 border-transparent rounded-3xl hover:border-gradient-to-r hover:from-blue-500 hover:via-purple-500 hover:to-pink-500 transition-all"></div>
      <div
        className="relative z-10 flex items-center justify-between"
        style={{ minWidth: '290px' }}
      >
        <img src={imgSrc} alt={imgAlt} className="w-16 h-16 ml-2 rounded-lg" loading="lazy" />
        <div className="py-4 pr-8 pl-4">
          <div className="grid grid-cols-1">
            <div className="flex justify-end mb-1">
              <span
                className="text-white rounded-full px-3 py-1 text-sm font-semibold"
                style={{ backgroundColor: bgColor }}
              >
                {category}
              </span>
            </div>
            <span className="text-sm flex justify-center text-gray-900 dark:text-gray-100">
              {title}
            </span>
          </div>
        </div>
      </div>
      <div className="flex mx-2 mb-2">
        {desc && <span className="text-gray-700 dark:text-gray-300 text-sm">{desc}</span>}
      </div>
    </div>
  </a>
);

export default LinkCard;
