import React from 'react';
import PropTypes from 'prop-types';

const TransactionHistoryTable = ({ transactions }) => {
  return (
    <div className="poppins bg-white text-gray-900 shadow-md rounded-lg p-8 w-full max-w-4xl">
      <h1 className="text-2xl font-bold mb-4 text-center">Transaction History</h1>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-blue-100">
              <th className="p-4 text-left">Date</th>
              <th className="p-4 text-left">Amount</th>
              <th className="p-4 text-left">Transaction ID</th>
              <th className="p-4 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? 'bg-white' : 'bg-gray-100'
                } hover:bg-blue-50 transition-colors duration-200`}
              >
                <td className="p-4">{transaction.date}</td>
                <td className="p-4">{transaction.amount}</td>
                <td className="p-4">{transaction.transactionId}</td>
                <td className="p-4">
                  <span
                    className={`inline-block px-3 py-1 text-sm font-semibold rounded-full ${
                      transaction.status === 'Completed'
                        ? 'bg-green-500 text-white'
                        : 'bg-yellow-500 text-white'
                    }`}
                  >
                    {transaction.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

TransactionHistoryTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
      transactionId: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TransactionHistoryTable;
