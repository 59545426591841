import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="py-6 poppins bg-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mb-4 md:mb-0">
            <Link to="/" className="text-xl font-bold" aria-label="Tailwine Home">
              Tailwine <span className="text-white bg-black rounded-md text-lg px-2">Studio</span>
            </Link>
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6 text-xs">
            <Link
              to="/"
              className="text-gray-700 hover:text-gray-600 transition-colors duration-300 hover:underline"
              aria-label="Home"
            >
              Home
            </Link>
            <Link
              to="/templates"
              className="text-gray-700 hover:text-gray-600 transition-colors duration-30 hover:underline"
              aria-label="Docs"
            >
              Templates
            </Link>
            <Link
              to="/pages"
              className="text-gray-700 hover:text-gray-600 transition-colors duration-300 hover:underline"
              aria-label="Pages"
            >
              Pages
            </Link>
            <Link
              to="/contact"
              className="text-gray-700 hover:text-gray-600 transition-colors duration-300 hover:underline"
              aria-label="Contact Us"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="mt-4 md:text-left text-xs text-gray-700">
          <p className="font-semibold">
            By{' '}
            <a href="https://mrmehta.in" target="_black" rel="noreferrer nopoener">
              Mehta.
            </a>
          </p>
          <p>All rights reserved. &copy; {new Date().getFullYear()} Tailwine</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
