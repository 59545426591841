import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({ src, alt, size, style }) => {
  return (
    <img
      src={src}
      alt={alt}
      className={`rounded-full ${size} ${style}`}
      aria-label={alt}
      role="img"
      loading="lazy"
    />
  );
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  size: PropTypes.string,
  style: PropTypes.string,
};

Avatar.defaultProps = {
  size: 'h-16 w-16',
  style: '',
};

export default Avatar;
