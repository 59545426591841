import React from 'react';
import TransactionHistoryTable from '../../components/TransactionHistoryTable';
import Code from '../../components/Reserve/Code';

const transactions = [
  { date: '2024-06-01', amount: '0.5 ETH', transactionId: '0x1234', status: 'Completed' },
  { date: '2024-06-02', amount: '1.0 ETH', transactionId: '0x5678', status: 'Pending' },
  { date: '2024-06-03', amount: '0.3 ETH', transactionId: '0x9101', status: 'Completed' },
  { date: '2024-06-04', amount: '0.8 ETH', transactionId: '0x1121', status: 'Completed' },
  { date: '2024-06-05', amount: '0.2 ETH', transactionId: '0x3141', status: 'Pending' },
];

const transactionHistoryCode = `
import React from 'react';
import PropTypes from 'prop-types';

const TransactionHistoryTable = ({ transactions }) => {
  return (
    <div className="poppins bg-white text-gray-900 shadow-md rounded-lg p-8 w-full max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4 text-center">Transaction History</h1>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-blue-100">
              <th className="p-4 text-left">Date</th>
              <th className="p-4 text-left">Amount</th>
              <th className="p-4 text-left">Transaction ID</th>
              <th className="p-4 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr
                key={index}
                className={\`\${
                  index % 2 === 0 ? 'bg-white' : 'bg-gray-100'
                } hover:bg-blue-50 transition-colors duration-200\`}
              >
                <td className="p-4">{transaction.date}</td>
                <td className="p-4">{transaction.amount}</td>
                <td className="p-4">{transaction.transactionId}</td>
                <td className="p-4">
                  <span
                    className={\`inline-block px-3 py-1 text-sm font-semibold rounded-full \${
                      transaction.status === 'Completed'
                        ? 'bg-green-500 text-white'
                        : 'bg-yellow-500 text-white'
                    }\`}
                  >
                    {transaction.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionHistoryTable;
`;

const TransactionHistory = () => {
  return (
    <div className="mt-16 container px-4">
      <h2 className="text-2xl font-bold mb-8 poppins">Component - Transaction History</h2>
      <div className="overflow-auto mt-4 flex justify-center py-12 component-container">
        <TransactionHistoryTable transactions={transactions} />
      </div>
      <Code code={transactionHistoryCode} />
    </div>
  );
};

export default TransactionHistory;
