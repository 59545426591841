import React from 'react';
import NFTGallery from '../../components/NFTGallery';
import Code from '../../components/Reserve/Code';
import nft1 from '../../assets/nft1.png';
import nft2 from '../../assets/nft2.png';
import nft3 from '../../assets/nft3.png';

const code = `import React from 'react';

const NFTCard = ({ image, name, description }) => (
  <div className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 dark:bg-gray-800">
    <img src={image} alt={name} className="w-full h-48 object-cover" />
    <div className="p-4">
      <h3 className="text-lg font-bold text-gray-900 dark:text-white">{name}</h3>
      <p className="text-gray-700 dark:text-gray-300">{description}</p>
    </div>
  </div>
);

const NFTGallery = ({ nfts }) => {
  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-8 poppins text-gray-900 dark:text-white">Gallery</h2>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {nfts.map((nft, index) => (
          <NFTCard key={index} {...nft} />
        ))}
      </div>
    </div>
  );
};

export default NFTGallery;
`;

const nfts = [
  {
    image: nft1,
    name: 'NFT 1',
    description: 'This is a description for NFT.',
  },
  {
    image: nft2,
    name: 'NFT 2',
    description: 'This is a description for NFT.',
  },
  {
    image: nft3,
    name: 'NFT 3',
    description: 'This is a description for NFT.',
  },
];

const NFTGalleryPage = () => {
  return (
    <div className="mt-16 container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-8 poppins">Component - NFT Gallery</h2>
      <div className="overflow-auto mt-4 flex justify-center py-12 component-container">
        <NFTGallery nfts={nfts} />
      </div>
      <Code code={code} />
    </div>
  );
};

export default NFTGalleryPage;
