import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import useWindowSize from '../hooks/useWindowSize';
import component from '../assets/component.png';
import './components.css';

// eslint-disable-next-line
const SidebarButton = ({ route, name, currentPath }) => {
  return (
    <li>
      <Link
        to={route}
        className={`block px-4 py-2 rounded-md transition-all duration-300 ${
          currentPath === `/components/${route}` ? 'font-bold' : 'text-gray-700'
        }`}
        aria-current={currentPath === `/components/${route}` ? 'page' : undefined}
      >
        {name}
      </Link>
    </li>
  );
};

const Components = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [theme, setTheme] = useState('Light');
  const { width } = useWindowSize();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (width < 768) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [width]);

  return (
    <div className="flex min-h-screen animate-fadeIn">
      <button
        onClick={toggleMenu}
        className="md:hidden p-4 focus:outline-none fixed bottom-4 right-4 z-20 bg-black text-white rounded-full"
        aria-label="Toggle sidebar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
          />
        </svg>
      </button>
      <CSSTransition in={isOpen} timeout={300} classNames="sidebar" unmountOnExit>
        <aside className="mt-16 fixed inset-y-0 left-0 w-64 bg-white p-4 z-10 md:relative md:translate-x-0 md:transform-none transform transition-transform duration-300 ease-in-out">
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => setTheme(theme === 'Light' ? 'Dark' : 'Light')}
            className="text-lg ml-4 underline"
          >
            {theme} Components
          </h4>
          <nav aria-label="Sidebar">
            <div className="flex ml-4 text-xs mb-4 text-gray-600">Click to Toggle theme</div>
            <ul className="space-y-2">
              {theme === 'Light' ? (
                <SidebarButton
                  route={'ens-resolver'}
                  name={'ENS Resolver'}
                  currentPath={location.pathname}
                />
              ) : (
                <SidebarButton
                  route={'ens-resolver-dark'}
                  name={'ENS Resolver'}
                  currentPath={location.pathname}
                />
              )}
              {theme === 'Light' ? (
                <SidebarButton
                  route={'nft-gallery'}
                  name={'NFT Gallery'}
                  currentPath={location.pathname}
                />
              ) : (
                <SidebarButton
                  route={'nft-gallery-dark'}
                  name={'NFT Gallery'}
                  currentPath={location.pathname}
                />
              )}
              {theme === 'Light' ? (
                <SidebarButton
                  route={'price-ticker'}
                  name={'Price Ticker'}
                  currentPath={location.pathname}
                />
              ) : (
                <SidebarButton
                  route={'price-ticker-dark'}
                  name={'Price Ticker'}
                  currentPath={location.pathname}
                />
              )}
              {theme === 'Light' ? (
                <SidebarButton
                  route={'token-balance'}
                  name={'Token Balance Display'}
                  currentPath={location.pathname}
                />
              ) : (
                <SidebarButton
                  route={'token-balance-dark'}
                  name={'Token Balance Display'}
                  currentPath={location.pathname}
                />
              )}
              {theme === 'Light' ? (
                <SidebarButton
                  route={'token-swap'}
                  name={'Token Swap Interface'}
                  currentPath={location.pathname}
                />
              ) : (
                <SidebarButton
                  route={'token-swap-dark'}
                  name={'Token Swap Interface'}
                  currentPath={location.pathname}
                />
              )}
              {theme === 'Light' ? (
                <SidebarButton
                  route={'transaction-form'}
                  name={'Transaction Form'}
                  currentPath={location.pathname}
                />
              ) : (
                <SidebarButton
                  route={'transaction-form-dark'}
                  name={'Transaction Form'}
                  currentPath={location.pathname}
                />
              )}
              {theme === 'Light' ? (
                <SidebarButton
                  route={'transaction-history'}
                  name={'Transaction History Table'}
                  currentPath={location.pathname}
                />
              ) : (
                <SidebarButton
                  route={'transaction-history-dark'}
                  name={'Transaction History Table'}
                  currentPath={location.pathname}
                />
              )}
              {theme === 'Light' ? (
                <SidebarButton
                  route={'user-profile'}
                  name={'User Profile'}
                  currentPath={location.pathname}
                />
              ) : (
                <SidebarButton
                  route={'user-profile-dark'}
                  name={'User Profile'}
                  currentPath={location.pathname}
                />
              )}
            </ul>
          </nav>
        </aside>
      </CSSTransition>
      <main
        className="flex-grow p-8 md:ml-24"
        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
      >
        {location.pathname === '/components' ? (
          <div className="poppins flex flex-col mt-16">
            <div className="p-6">
              <img
                src={component}
                alt="Component Banner"
                className="w-full max-w-4xl mb-8"
                loading="lazy"
              />
              <h3 className="text-2xl underline font-bold mb-4">Components</h3>
              <p className="text-gray-700 text-md mb-4">
                Please select a component from the sidebar to view its details and examples. <br />
                Our component library offers a variety of elements to enhance your projects.
              </p>
              <p className="text-gray-700 text-md mb-4">
                If you have any questions, feel free to reach out to our support team.
              </p>
              <p className="text-gray-700 text-md">
                Visit the{' '}
                <Link to="/documentation">
                  <span className="underline">documentation page</span>
                </Link>{' '}
                for the setup instructions.
              </p>
            </div>
          </div>
        ) : (
          <Outlet />
        )}
      </main>
    </div>
  );
};

export default Components;
