import React from 'react';
import PriceTicker from '../../components/PriceTickerDark';
import Code from '../../components/Reserve/Code';
import Chart from 'react-apexcharts';

const code = `import React from 'react';

const PriceTicker = ({ name, price, change, marketCap }) => {
  const changeColor = change > 0 ? 'text-green-400' : 'text-red-400';
  return (
    <div className="bg-gray-900 shadow-md rounded-lg p-4 flex items-center justify-between">
      <div>
        <h3 className="text-lg font-bold text-gray-100">{name}</h3>
        <p className="text-sm text-gray-400">Market Cap: \${marketCap}</p>
      </div>
      <div className="text-right">
        <p className="text-xl font-semibold text-gray-100">\${price}</p>
        <p className={\`text-sm \${changeColor}\`}>{change}%</p>
      </div>
    </div>
  );
};

export default PriceTicker;

`;

const tickers = [
  {
    name: 'Bitcoin',
    price: 50000,
    change: 2.5,
    marketCap: 900000000000,
  },
  {
    name: 'Ethereum',
    price: 3500,
    change: -1.2,
    marketCap: 400000000000,
  },
  {
    name: 'Cardano',
    price: 2.5,
    change: 5.1,
    marketCap: 80000000000,
  },
];

const chartOptions = {
  chart: {
    type: 'line',
    background: '#1a202c',
    foreColor: '#a0aec0',
  },
  series: [
    {
      name: 'Price',
      data: [50000, 50500, 51000, 49500, 48000, 47000, 46000],
    },
  ],
  xaxis: {
    categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    labels: {
      style: {
        colors: '#a0aec0',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#a0aec0',
      },
    },
  },
};

const PriceTickerDarkPage = () => {
  return (
    <div className="mt-16 container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-8 poppins">Component - Price Ticker</h2>
      <div className="overflow-auto mt-4 py-12 component-container">
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-8">
          {tickers.map((ticker, index) => (
            <PriceTicker key={index} {...ticker} />
          ))}
        </div>
        <h2 className="text-2xl font-bold mb-8 poppins">Price Chart</h2>
        <div className="bg-gray-900 p-4 rounded-lg shadow-md">
          <Chart options={chartOptions} series={chartOptions.series} type="line" height="350" />
        </div>
      </div>
      <Code code={code + JSON.stringify(tickers, null, 2)} />
    </div>
  );
};

export default PriceTickerDarkPage;
