import React from 'react';
import ENSResolver from '../../components/ENSResolverDark';
import Code from '../../components/Reserve/Code';

const ensResolverCode = `
import React, { useState } from 'react';

const ENSResolver = () => {
  const [ensName, setEnsName] = useState('');
  const [resolvedAddress, setResolvedAddress] = useState('');
  const [error, setError] = useState('');

  const resolveENS = async () => {
    try {
      const response = await fetch(\`https://api.ensideas.com/ens/resolve/\${ensName}\`);
      const data = await response.json();

      if (data.address) {
        setResolvedAddress(data.address);
        setError('');
      } else {
        setResolvedAddress('');
        setError('Could not resolve ENS name');
      }
    } catch (err) {
      setResolvedAddress('');
      setError('An error occurred while resolving ENS name');
    }
  };

  return (
    <div className="poppins bg-gray-900 text-white shadow-lg rounded-lg p-8 w-full max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-purple-400 mb-4 text-center">ENS Resolver</h1>
      <div className="mb-4">
        <label htmlFor="ens-name" className="block text-sm text-gray-300 mb-2">
          ENS Name
        </label>
        <input
          id="ens-name"
          type="text"
          value={ensName}
          onChange={(e) => setEnsName(e.target.value)}
          className="w-full px-4 py-2 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 bg-gray-800 text-white"
          placeholder="Enter ENS Name"
        />
      </div>
      <button
        onClick={resolveENS}
        className="w-full bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition-colors duration-300"
      >
        Resolve
      </button>
      {resolvedAddress && (
        <div className="mt-4 text-center">
          <p className="text-sm font-semibold text-gray-300">Address: {resolvedAddress}</p>
        </div>
      )}
      {error && (
        <div className="mt-4 text-center">
          <p className="text-lg font-semibold text-red-400">{error}</p>
        </div>
      )}
    </div>
  );
};

export default ENSResolver;
`;

const ENSResolverPage = () => {
  return (
    <div className="mt-16 container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-8 poppins">Component - ENS Resolver</h2>
      <div className="overflow-auto mt-4 flex justify-center py-12 component-container">
        <ENSResolver />
      </div>
      <Code code={ensResolverCode} />
    </div>
  );
};

export default ENSResolverPage;
