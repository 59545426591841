import React from 'react';
import NFTGalleryDark from '../../components/NFTGalleryDark';
import Code from '../../components/Reserve/Code';
import nft1 from '../../assets/nft1.png';
import nft2 from '../../assets/nft2.png';
import nft3 from '../../assets/nft3.png';

const code = `import React from 'react';

const NFTCardDark = ({ image, name, description }) => (
  <div className="bg-gray-800 border border-black rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 dark:bg-gray-900">
    <img src={image} alt={name} className="w-full h-48 object-cover bg-white" />
    <div className="p-4">
      <h3 className="text-lg font-bold text-white">{name}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
  </div>
);

const NFTGalleryDark = ({ nfts }) => {
  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-8 poppins text-gray-700">Gallery</h2>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {nfts.map((nft, index) => (
          <NFTCardDark key={index} {...nft} />
        ))}
      </div>
    </div>
  );
};

export default NFTGalleryDark;
`;

const nfts = [
  {
    image: nft1,
    name: 'NFT 1',
    description: 'This is a description for NFT.',
  },
  {
    image: nft2,
    name: 'NFT 2',
    description: 'This is a description for NFT.',
  },
  {
    image: nft3,
    name: 'NFT 3',
    description: 'This is a description for NFT.',
  },
];

const NFTGalleryDarkPage = () => {
  return (
    <div className="mt-16 container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-8 poppins">Component - NFT Gallery</h2>
      <div className="overflow-auto mt-4 flex justify-center py-12 component-container">
        <NFTGalleryDark nfts={nfts} />
      </div>
      <Code code={code} />
    </div>
  );
};

export default NFTGalleryDarkPage;
