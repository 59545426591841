import React from 'react';
import { Link } from 'react-router-dom';

const StickyButton = ({ position }) => {
  return (
    <div className={`fixed bottom-4 ${position ? position : 'right'}-4`}>
      <Link to="/pages" aria-label="Go to Pages">
        <button className="bg-black text-white px-4 py-2 rounded-full" aria-label="Go to Pages">
          Go Back
        </button>
      </Link>
    </div>
  );
};

export default StickyButton;
