import React from 'react';
import useOnlineStatus from './useOnlineStatus';
import bannerImage from './assets/banner.png';
import StickyButton from '../../components/Reserve/StickyButton';
import TicTacToeGame from './TicTacToeGame';

const TicTacToe = () => {
  const isOnline = useOnlineStatus();

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen p-4 animate-fadeIn">
        {isOnline ? (
          <div className="text-center">
            <img src={bannerImage} alt="Banner" className="w-full max-w-4xl mb-4" />
            <p className="poppins text-lg font-semibold text-gray-700">
              Go offline if you want to play Tic Tac Toe.
            </p>
          </div>
        ) : (
          <TicTacToeGame />
        )}
      </div>
      <StickyButton />
    </>
  );
};

export default TicTacToe;
