import React from 'react';
import spooky from '../assets/spooky.png';

const NotFound = () => {
  return (
    <div className="poppins flex flex-col justify-center items-center min-h-screen">
      <div className="container p-4 text-gray-900 flex flex-col items-center justify-center">
        <img src={spooky} alt="Spooky 404" className="w-full max-w-4xl mb-8" loading="lazy" />
        <div
          className="text-center p-8 bg-white"
          role="alert"
          aria-labelledby="not-found-title"
          aria-describedby="not-found-description"
        >
          <h1 id="not-found-title" className="text-5xl font-bold mb-4">
            404
          </h1>
          <h2 className="text-3xl font-semibold mb-4">Page Not Found</h2>
          <p id="not-found-description" className="text-lg text-gray-700 mb-8">
            The page you are looking for does not exist. It might have been moved or deleted.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
