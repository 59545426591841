import React from 'react';
import rnk from '../assets/rnk.webp';
import wab from '../assets/wab.webp';
import lai from '../assets/lai.png';
import template from '../assets/template.png';
import surya from '../assets/surya.webp';
import discord from '../assets/discord_bot.webp';

const templates = [
  {
    title: 'React Node Kit',
    image: rnk,
    description:
      'React Node Kit is a versatile full-stack starter template designed to streamline the development of web applications using Vite, React, and Node.js.',
    link: 'https://lightning.ai/mehta/studios/react-node-kit',
  },
  {
    title: 'WhatsApp AI Bot',
    image: wab,
    description:
      'WhatsApp AI Bot is a messaging response system that integrates state-of-the-art AI models to provide dynamic command handling via WhatsApp.',
    link: 'https://lightning.ai/mehta/studios/whatsapp-ai-bot',
  },
  {
    title: 'OCR Implementation',
    image: surya,
    description:
      'Surya OCR is a Python-based Optical Character Recognition (OCR) application designed to extract text from images. Powered by Streamlit.',
    link: 'https://lightning.ai/mehta/studios/surya-ocr-implementation',
  },
  {
    title: 'VACCARIM - Discord Bot',
    image: discord,
    description:
      "VACCARIM is a Discord bot designed to assist with code completion and custom image generation using OpenAI's API.",
    link: 'https://lightning.ai/mehta/studios/vaccarim-an-ai-discord-bot',
  },
];

const Templates = () => {
  return (
    <div className="flex min-h-screen flex-col items-center animate-fadeIn container mx-auto p-4">
      <img
        src={template}
        alt="Landing Banner"
        className="w-full max-w-4xl g mb-8 mt-16"
        loading="lazy"
      />
      <h3 className="poppins text-2xl underline font-bold mb-4">Templates</h3>
      <div className="grid gap-8 grid-cols-1 lg:grid-cols-2">
        {templates.map((template, index) => (
          <div
            key={index}
            className="poppins bg-white overflow-hidden transition-transform transform hover:scale-105"
          >
            <a href={template.link} target="_blank" className="flex flex-col lg:flex-row">
              <img
                src={template.image}
                alt={template.title}
                className="w-full lg:w-1/2 object-cover"
                loading="lazy"
              />
              <div className="p-4 flex flex-col lg:w-1/2">
                <h2 className="text-xl font-bold text-gray-900">{template.title} on</h2>
                <img src={lai} alt="Lightening AI" className="w-20 mb-4" />
                <p className="text-gray-700 text-sm">{template.description}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Templates;
