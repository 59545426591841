import React from 'react';
import banner from '../assets/banner.png';

const Home = () => {
  return (
    <div className="poppins min-h-screen text-gray-900 flex flex-col">
      <main className="flex-grow flex flex-col items-center justify-center" role="main">
        <div className="w-full max-w-4xl p-4 animate-fadeIn">
          <img src={banner} alt="Tailwine Banner" className="w-full h-auto mb-8" loading="lazy" />
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-4 bg-clip-text text-black">
              Hello from Tailwine
            </h1>
            <p className="text-md sm:text-lg md:text-xl text-gray-700 mb-8">
              Every idea holds a story waiting to be told. Our mission is to shape that story—clear,
              compelling, and built to last. Ready to bring your vision to life?
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
