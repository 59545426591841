import React, { useState } from 'react';
import cloudflare from './assets/cloudflare.png';
import loader from './assets/loader.svg';
import StickyButton from '../../components/Reserve/StickyButton';
import banner from './assets/banner.png';
import './ping.css';

const Ping = () => {
  const [pingResults, setPingResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const measurePing = async () => {
    setIsLoading(true);
    const numberOfPings = 5;
    const results = [];
    try {
      for (let i = 0; i < numberOfPings; i++) {
        const startTime = Date.now();
        await fetch('https://cloudflare.com/cdn-cgi/trace');
        const endTime = Date.now();
        const pingTime = endTime - startTime;
        results.push(pingTime);
        setPingResults([...results]);
        await delay(1000);
      }
      const averagePingTime = results.reduce((acc, time) => acc + time, 0) / numberOfPings;
      setPingResults([averagePingTime]);
    } catch {
      setPingResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <h1 className="flex poppins text-3xl justify  font-bold m-6  items-center">
        Ping to Cloudflare
        <img src={cloudflare} className="ml-4 h-16 w-16" alt="Cloudflare Logo" loading="lazy" />
      </h1>
      <div className="flex justify-center">
        <button
          className={`poppins text-xl bottom-4 fixed right-4 bg-black text-white font-semibold py-2 px-4 rounded-full h-24 w-24 focus:outline-none transition-opacity ${
            isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={measurePing}
          disabled={isLoading}
        >
          {isLoading ? <img src={loader} alt="Loader" /> : 'Go'}
        </button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="mt-4 text-center">
          {pingResults.length > 0 && (
            <div className="ping-results">
              {pingResults.map((result, index) => (
                <p
                  key={index}
                  className={`ping-result animate-ping-${index === pingResults.length - 1 ? 'center' : 'fade'}`}
                >
                  {index === pingResults.length - 1 ? (
                    <span className="poppins font-semibold text-3xl">
                      Average Ping
                      <br />
                      <br />
                      <span className="text-6xl">{result.toFixed(2)} ms</span>
                    </span>
                  ) : (
                    ` ${result.toFixed(2)} ms`
                  )}
                </p>
              ))}
            </div>
          )}
          {pingResults.length === 0 && !isLoading && (
            <>
              <div className="px-4 relative container p-4 text-gray-900 flex flex-col items-center justify-center">
                <img src={banner} alt="Banner" className="w-full max-w-3xl mb-8" loading="lazy" />
              </div>
              <p className="text-black font-bold text-md font-bold poppins">
                Speed measures data transfer rate over networks.
              </p>
            </>
          )}
        </div>
      </div>
      <StickyButton position=" ml-4 left" />
    </>
  );
};

export default Ping;
