import React, { useState } from 'react';
import './tictactoe.css';

const TicTacToeGame = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const [winner, setWinner] = useState(null);

  const handleClick = (index) => {
    if (board[index] || winner) return;

    const newBoard = board.slice();
    newBoard[index] = isXNext ? 'X' : 'O';
    setBoard(newBoard);
    setIsXNext(!isXNext);

    const gameWinner = calculateWinner(newBoard);
    if (gameWinner) {
      setWinner(gameWinner);
    } else if (!newBoard.includes(null)) {
      setWinner('Draw');
    }
  };

  const calculateWinner = (board) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    for (let line of lines) {
      const [a, b, c] = line;
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        return board[a];
      }
    }

    return null;
  };

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setIsXNext(true);
    setWinner(null);
  };

  return (
    <div className="poppins game-container flex flex-col items-center justify-center min-h-screen p-4">
      <h1 className="text-3xl font-bold mb-4">Tic Tac Toe</h1>
      <div className="board grid grid-cols-3 gap-4 mb-4">
        {board.map((value, index) => (
          <div
            key={index}
            className={`cell flex items-center justify-center border ${
              value === 'X' ? 'text-red-500' : 'text-green-500'
            }`}
            onClick={() => handleClick(index)}
          >
            {value}
          </div>
        ))}
      </div>
      <div className="status text-center mb-4">
        {winner ? (
          <p className="text-xl font-semibold">
            {winner === 'Draw' ? "It's a Draw!" : `Winner: ${winner}`}
          </p>
        ) : (
          <p className="text-xl font-semibold">Next Player: {isXNext ? 'X' : 'O'}</p>
        )}
      </div>
      <button className="reset-button text-white px-4 py-2 bg-black" onClick={resetGame}>
        Reset Game
      </button>
    </div>
  );
};

export default TicTacToeGame;
