import Components from './Components';
import Contact from './Contact';
import Documentation from './Documentation';
import ENSResolverPage from './components/ENSResolverDark';
import ENSResolverPageLight from './components/ENSResolver';
import Home from './Home';
import Templates from './Templates';
import LandingPage from './LandingPage';
import NotFound from './NotFound';
import TokenSwapPage from './components/TokenSwap';
import TokenSwapPageDark from './components/TokenSwapDark';
import TransactionHistory from './components/TransactionHistory';
import TransactionHistoryDark from './components/TransactionHistoryDark';
import UserProfilePage from './components/UserProfilePage';
import DarkUserProfilePage from './components/DarkUserProfilePage';
import NFTGalleryPage from './components/NFTGalleryPage';
import NFTGalleryDarkPage from './components/NFTGalleryDarkPage';
import PriceTickerPage from './components/PriceTickerPage';
import PriceTickerDarkPage from './components/PriceTickerDarkPage';
import TokenBalancePage from './components/TokenBalancePage';
import TokenBalanceDarkPage from './components/TokenBalanceDarkPage';
import TransactionFormPage from './components/TransactionFormPage';
import DarkTransactionFormPage from './components/DarkTransactionFormPage';

export {
  Components,
  Contact,
  Documentation,
  ENSResolverPage,
  ENSResolverPageLight,
  Home,
  Templates,
  LandingPage,
  NotFound,
  TokenSwapPage,
  TokenSwapPageDark,
  TransactionHistory,
  TransactionHistoryDark,
  UserProfilePage,
  DarkUserProfilePage,
  NFTGalleryPage,
  NFTGalleryDarkPage,
  PriceTickerPage,
  PriceTickerDarkPage,
  TokenBalancePage,
  TokenBalanceDarkPage,
  TransactionFormPage,
  DarkTransactionFormPage,
};
