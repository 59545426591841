import React from 'react';
import PropTypes from 'prop-types';

const TokenBalance = ({ tokenName, balance, logo }) => {
  return (
    <div className="flex items-center bg-gray-900 shadow-lg p-4 rounded-lg">
      <img src={logo} alt={`${tokenName} logo`} className="w-12 h-12 rounded-full mr-4" />
      <div className="flex flex-col">
        <span className="text-lg font-bold text-white">{tokenName}</span>
        <span className="text-sm text-gray-400">{balance} tokens</span>
      </div>
    </div>
  );
};

TokenBalance.propTypes = {
  tokenName: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  logo: PropTypes.string.isRequired,
};

export default TokenBalance;
