import React from 'react';
import Card from '../components/Reserve/Card';
import landing from '../assets/landing.png';
import portfolio from '../assets/portfolio.png';
// import ping from '../assets/ping.png';
import tictactoe from '../assets/tictactoe.png';
import reputation from '../assets/reputation.webp';

const pages = [
  {
    title: 'Portfolio',
    description:
      'Crafted a responsive and accessible portfolio using a minimalistic component library.',
    image: portfolio,
    link: '/portfolio',
    code: 'https://gist.github.com/kartikmehta8/c58d772823ff512ff5c627ec42149070',
  },
  {
    title: 'Reputation Framework',
    description:
      'A ranking site using Jekyll, displaying commits, lines of code, and contributor details.',
    image: reputation,
    link: '/',
    code: 'https://github.com/kartikmehta8/reputation-framework',
  },
  // {
  //   title: 'Ping to Cloudflare',
  //   description:
  //     'Build a sleek ping test website, accurately measuring average speed for seamless user experience.',
  //   image: ping,
  //   link: '/ping',
  //   code: 'https://gist.github.com/kartikmehta8/dc83603a9af3d8c2d23d2f9b4b7d9d7b',
  // },
  {
    title: 'Tic Tac Toe',
    description: "A dynamic Tic Tac Toe game that changes content based on user's online status.",
    image: tictactoe,
    link: '/tictactoe',
    code: 'https://gist.github.com/kartikmehta8/5a085712fdaa485c2b40d4f55f1fb49b',
  },
];

const LandingPage = () => {
  return (
    <div className="flex justify-center">
      <div className="poppins container p-4 text-gray-900 min-h-screen animate-fadeIn">
        <div className="flex flex-col items-center mt-16">
          <img
            src={landing}
            alt="Landing Banner"
            className="w-full max-w-4xl g mb-8"
            loading="lazy"
          />
          <h1 className="text-2xl underline font-bold mb-4">Site Pages</h1>
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {pages.map((page, index) => (
              <Card
                key={index}
                title={page.title}
                description={page.description}
                image={page.image}
                link={page.link}
                code={page.code}
                aria-label={`${page.title} - ${page.description}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
